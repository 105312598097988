.input{
  width: 80% !important;
  margin: 10px !important;
}

.input input{
  width: 100%;
}

#add-build-btn{
  background: #3f51b5;
  color: white;
  margin-right: 20px;
}

.inner-modal{
  background-color: white;
  width: 300px;
  padding: 15px;
  border-radius: 6px;
  margin: 100px auto;
  text-align: right;
}

.loader {
  border: 8px solid white; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.row-time-passed{
  background:#ff726f ;
}

.building-row:hover{
  background: #87CEFA;
  cursor: pointer;
}

.input > label{
  right:0 !important;
  left:auto !important;
}

.apartment-modal{
  background-color: white;
  padding: 15px;
  border-radius: 6px;
  text-align: right;
  margin: 20px auto;
  max-width: 630px;
}

.apartment-modal .input{
  width: auto !important;
}
[componentname="organization-auto"] > div > div{
  padding-right: 0px !important;
  padding-left: 30px !important;
}

[componentname="organization-auto"] >div >div >div{
  left:0;
  right:auto;
}


.summary-card:hover{
  background-color: #3498db;
  color: white;
}